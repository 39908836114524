<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span> 6</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        Abre una pestaña del navegador (Chrome)
      </div>
    </div>
    <img
      src="/6.jpg"
      class="my-2 align-self-center"
      height="57px"
      width="267px"
      alt="mid-box"
    />

    <div class="d-flex">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span> 7</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        Escribir la siguiente dirección: 192.168.4.1 y accede a ella.
      </div>
    </div>
    <img
      src="/7.jpg"
      class="my-2 align-self-center"
      height="57px"
      width="267px"
      alt="mid-box"
    />

    <div class="d-flex">
      <div
        class="
          align-self-center
          text-white
          rounded-circle
          bg-dark-green
          font-rem-1-0
          mx-3
          d-flex
          justify-content-center
          align-items-center
        "
        :style="{
          'min-width': '30px',
          width: '30px',
          'min-height': '30px',
          height: '30px'
        }"
      >
        <span> 8</span>
      </div>
      <div class="brown-green-bold text-left font-rem-0-9 pt-5">
        En los recuadros escribe el nombre de la red wifi de tu casa y la
        contraseña. Da clic en aceptar.
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',[_vm._v(" 6")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Abre una pestaña del navegador (Chrome) ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/6.jpg","height":"57px","width":"267px","alt":"mid-box"}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',[_vm._v(" 7")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" Escribir la siguiente dirección: 192.168.4.1 y accede a ella. ")])]),_c('img',{staticClass:"my-2 align-self-center",attrs:{"src":"/7.jpg","height":"57px","width":"267px","alt":"mid-box"}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"\n        align-self-center\n        text-white\n        rounded-circle\n        bg-dark-green\n        font-rem-1-0\n        mx-3\n        d-flex\n        justify-content-center\n        align-items-center\n      ",style:({
        'min-width': '30px',
        width: '30px',
        'min-height': '30px',
        height: '30px'
      })},[_c('span',[_vm._v(" 8")])]),_c('div',{staticClass:"brown-green-bold text-left font-rem-0-9 pt-5"},[_vm._v(" En los recuadros escribe el nombre de la red wifi de tu casa y la contraseña. Da clic en aceptar. ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }